@import '../../../../assets/sass/variables';

$_shape: $shape;
$_shadow: 0px 6px 18px rgba(#000, 0.06);
$_row-shadow-hover: 0px 2px 8px rgba(#000, 0.1);

.Data-table-container {
  display: block;
  max-width: 100%;

  .top-bar {
    align-items: flex-end;
    display: flex;
    margin: 0 (-$module-rem);
    margin-bottom: $module-rem * 2.4;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin: 0 0 $module-rem * 1.6;

      .tc-btn {
        order: -1;
        display: block;
        width: 100%;
        margin-bottom: $module-rem * 3.2;
      }
      .selects {
        width: 100%;
        justify-content: flex-start;
        margin: 0;

        .search {
          margin-left: auto;
          display: inline-block;
        }
      }
    }
    .header-select-wrap {
      margin-right: $module-rem * 2;
    }
    .search {
      display: none;
      width: 24px;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $table-gray;
      }
    }
    > * {
      margin: 0 $module-rem;
    }
    .tc-btn {
      margin-left: auto;
      min-width: 160px;
    }
    .selects {
      align-items: center;
      display: flex;
    }

    .label {
      font-size: 12px;
      color: #6a707e;
    }
  }
  .contacts-table {
    .cdk-column-forecast {
      min-width: 60px;
    }

    .email-td {
      overflow: hidden;
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .delete-btn {
      cursor: pointer;
      font-size: 16px;
      height: 16px;
      transition: color 0.2s ease-in-out;
      width: 16px;

      &:hover {
        color: $red;
      }
    }
  }
}

.user-wrap {
  //align-items: center;
  display: flex;

  .contact-img {
    border-radius: 50%;
    height: 25px;
    margin-right: $module-rem * 1.2;
  }

  .name {
    font-weight: 500;
    font-size: 14px;
    color: $table-black;
    line-height: 30px;
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    .name {
      color: $accent-blue;
    }
  }
}

.MuiTableRow-root.Mui-selected {
  background-color: #f2f9ff;
}

.ont-badge {
  display: block;
  line-height: 25px;
  padding: 0 5px;
  border-radius: 10px;
  text-align: center;
  color: white;

  &.active {
    background: $green;
  }
  &.archived {
    background: $yellow;
  }
  &.completed {
    background: $green;
  }
  &.pending {
    background: $yellow;
  }
  &.progress {
    background: $accent-blue;
  }
  &.denied {
    background: $red;
  }
}

.ont-text-color {
  &.active {
    color: $green;
  }
  &.no-active {
    color: $red;
  }
}

.edit-wrap {
  display: flex;
  color: $table-black;

  .edit-text {
    font-size: 14px;
  }
  .icon {
    margin-left: 5px;
    color: $yellow;
  }
}

.icon-wrap {
  display: flex;
  justify-items: center;
  align-items: center;
  .print-icon,
  .view-icon {
    cursor: pointer;
  }
  .print-icon {
    color: $accent-blue;
  }
  .view-icon {
    // margin-left: 5px;
    color: $yellow;
  }
}

.dot-wrap {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  height: 1.7em;
  white-space: nowrap;
}
