@import '../../../assets/sass/variables';

.to_the_right {
  margin-right: auto;
}
.to_the_left {
  margin-left: auto;
}

.identification-information-container {
  padding: 0 20px;
  .card {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    border: 1px solid #ebeff2;
    border-radius: 5px;
    padding: 15px;
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    min-height: 100px;
    &:hover {
      z-index: 10;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
    .icon {
      width: 50px;
      height: 50px;
      line-height: 35px;
      border-radius: 50%;
      font-size: 50px;
      text-align: center;
      background: $accent-blue;
      margin-bottom: 10px;
    }

    .mat-icon {
      color: #fff;
    }
  }
}

.patient-image-actions {
  padding: 10px 0 0 0;
  a,
  span {
    font-size: 16px;
    display: flex;
    color: $accent-blue;
    cursor: pointer;
  }
}

.patient-details-page {
  .card_title {
    display: flex;
    border-bottom: 1px solid $divider;
    padding: 15px;

    .cart_title_action {
      color: $accent-blue;
      font-weight: bold;
      cursor: pointer;
    }
    .card_title_test {
      padding: 0;
      margin: 0;
      font-size: 16px;
      color: $dark-blue-text;
      font-weight: bold;
    }
  }

  .patient_status {
    display: flex;
    .status_btn {
      display: block;
      line-height: 25px;
      padding: 0 15px;
      border-radius: 5px;
      text-align: center;
      color: white;
      margin: 10px 15px;
      cursor: pointer;

      &.active {
        background: $green;
        &.block_active {
          border: 2px solid $green;
          background: none;
          color: $green;
        }
      }
      &.block {
        background: $red;
        &.active_block {
          border: 2px solid $red;
          background: none;
          color: $red;
        }
      }
    }
  }
}

.dependents_list_container {
  border: 1px solid #ebeff2;
  //margin-top: 35px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .dependents_list {
    display: flex;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid #ebeff2;
    padding: 15px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
      z-index: 10;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      .edit-wrap {
        color: $accent-blue;
        font-weight: bold;
      }
    }

    .edit-wrap {
      display: flex;
      color: $table-black;
      cursor: pointer;
      &:hover {
        color: $accent-blue;
        font-weight: bold;
      }

      .edit-text {
        font-size: 14px;
      }
      .icon {
        margin-left: 10px;
        color: $yellow;
      }
    }
  }
}
