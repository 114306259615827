@import '../../../../assets/sass/variables';

.to_the_right {
  margin-right: auto;
}
.to_the_left {
  margin-left: auto;
}
.contact-info {
  .card_title {
    display: flex;
    border-bottom: 1px solid $divider;
    padding: 15px;

    .cart_title_action {
      color: $accent-blue;
      font-weight: bold;
      cursor: pointer;
    }
    .card_title_test {
      padding: 0;
      margin: 0;
      font-size: 16px;
      color: $dark-blue-text;
      font-weight: bold;
    }
  }
}

.notes_container {
  .note {
    background-color: #e6f6ff;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.06);
  }
  .note_content {
    color: $table-black;
    font-size: 14px;
  }
  .note_footer {
    display: flex;

    .note_author {
      color: $table-gray;
      font-size: 12px;
    }
  }
}
