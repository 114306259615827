@import '../../../assets/sass/variables';
.count-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  justify-content: space-around;
  .count-item {
    width: 100%;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
    // &:nth-child(2) {
    //   padding: 0 20px;
    // }
  }
}

.downloads-container {
  padding-bottom: 2%;
  .downloads-metric {
    h6 {
      margin-bottom: 0;
      text-align: left;
    }
  }
}

.chart-data-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  .chart-item {
    width: 100%;
    padding: 50px 0;
    &:nth-child(1) {
      padding-right: 20px;
    }
  }
}
.precription_dash_list_container {
  border: 1px solid #ebeff2;
  //margin-top: 35px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .precription_dash_list {
    display: flex;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid #ebeff2;
    padding: 15px;
    transition: box-shadow 0.2s ease-in-out;
    &.hover {
      &:hover {
        z-index: 10;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      }
    }

    &.header {
      span {
        color: $gray;
      }
    }

    div {
      width: 33%;
      display: flex;
      justify-content: center;
    }

    .to_left {
      margin-left: auto;
    }
    .to_right {
      margin-right: auto;
    }

    .edit-wrap {
      display: flex;
      color: $table-black;
      &:hover {
        color: $accent-blue;
        font-weight: bold;
      }

      .edit-text {
        font-size: 14px;
      }
      .icon {
        margin-left: 10px;
        color: $yellow;
      }
    }

    .ont-badge {
      display: block;
      line-height: 25px;
      padding: 0 10px;
      border-radius: 10px;
      text-align: center;
      color: white;

      &.active {
        background: $green;
      }
      &.archived {
        background: $yellow;
      }
      &.completed {
        background: $green;
      }
      &.pending {
        background: $yellow;
      }
      &.progress {
        background: $accent-blue;
      }
      &.denied {
        background: $red;
      }
    }
  }
}
