//alert
@import '../../../../assets/sass/variables';
.alert {
  min-width: 150px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  &-success {
    background-color: lighten($accent-blue, 40%);
    border-color: lighten($accent-blue, 30%);
    color: darken($accent-blue, 15%);
  }
  &-warning {
    background-color: lighten($yellow, 30%);
    border-color: lighten($yellow, 30%);
    color: darken($yellow, 15%);
  }
  &-danger {
    background-color: lighten($red, 30%);
    border-color: lighten($red, 30%);
    color: darken($red, 15%);
  }
  p {
    padding: 0;
    margin: 0;
    font-size: medium;
  }
  i {
    padding-right: 5px;
    vertical-align: middle;
    font-size: 24px;
  }
  .close-alert {
    -webkit-appearance: none;
    position: relative;
    float: right;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: inherit;
    background: 0 0;
    font-size: 21px;
    line-height: 1;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
    filter: alpha(opacity=40);
    opacity: 0.4;
    &:hover {
      filter: alpha(opacity=70);
      opacity: 0.7;
    }
  }
}
