@import '../../../../assets/sass/variables';
$_shape: $shape;

.mat-icon {
  cursor: pointer;
  font-size: 20px;
  height: 20px;
  width: 20px;
}

.MuiList-root {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.MuiListItem-root {
  padding: ($module-rem * 1.6) ($module-rem * 1.6);
  display: flex;
  align-items: flex-start;
  min-height: 36px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba($divider, 0.8);
  }
}

.item-content {
  display: flex;
  align-items: flex-start;
  min-height: 36px;

  .mat-icon {
    color: $icon-gray;
    margin-top: $module-rem * 0.5;
    margin-right: $module-rem * 1.5;
  }
  .text {
    height: 100%;
    flex-direction: column;
    &.center {
      text-align: center;
    }

    .link {
      color: $black;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $accent-blue;
      }
    }
  }
}

.MuiTouchRipple-root {
  &:hover {
    background: #fff;
  }
}
