@import '../../../assets/sass/variables';

$_shape: $shape;
$_profile-gray: #4c5862;

$_facebook-color: #3b5998;
$_twitter-color: #00acee;

.prescription-details-page {
  .tc-btn {
    display: block;
    width: 100%;
    padding: 15px !important;
    margin-top: 35px !important;
  }
  .delete_btn {
    margin-top: 60px;
  }
  .card_shadow {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  }
  .card_title {
    font-size: 16px;
    color: $dark-blue-text;
    font-weight: bold;
  }

  .download {
    float: right;
    padding: 10px 0 0 0;
    a {
      font-size: 16px;
      display: flex;
      color: $accent-blue;
    }
  }

  .barcode {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    //margin: 10% 0;
    align-items: center;
  }

  .action {
    margin-bottom: 40px;

    .attached-note {
      background-color: #f7f6a0;
      padding: 20px;
      margin-bottom: 30px;
      box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.06);
    }
    .assign_link {
      color: $accent-blue;
      cursor: pointer;
    }
    .text {
      //color: $black;
      margin-left: 20px;
      font-weight: bold;
      .link {
        color: $accent-blue;
        cursor: pointer;
      }
    }
    .select_text {
      color: $black !important;
      font-weight: bold !important;
    }
    .date {
      color: $gray;
      margin-left: 20px;
      font-weight: bold;
    }
    .title {
      color: $dark-blue-text;
      margin-bottom: 10px;
    }

    .delivery {
      display: flex;
      color: $dark-blue-text;
      margin-bottom: 25px;
    }
  }
}
