.list-page-header {
  display: flex;
  margin-bottom: 35px;
  .filter {
    label {
      margin-right: 5px;
      color: black;
      font-size: 14px;
    }
  }
  .create-btn {
    margin-left: auto;
  }
}
